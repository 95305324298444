import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

export default class IndexPage extends React.Component {
  render() {
    const { posts, title } = this.props

    return (
      <section className="section">
        <div className="container">
            <div className="content">
              <h1 className="has-text-weight-medium is-size-4">{title}</h1>
            </div>
          {posts.map(({ node: post }) => (
            <div
              className="content"
              style={{ border: '1px solid #eaecee', padding: '2em 3em' }}
              key={post.id}
            >
              <div className="columns">
                <div className="column">
                  <Img
                      sizes={
                        post.acf.feat_img.localFile.childImageSharp.sizes
                      }
                      style={{ maxWidth: 450 }}
                  />
                </div>
                <div className="column">
                  <div>
                    <Link className="has-text-primary" to={post.slug}>
                      {post.title}
                    </Link>
                    <span> &bull; </span>
                    <small>
                      {post.date} - von {post.author.name}
                    </small>
                  </div>
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: post.excerpt.replace(/<p class="link-more.*/, ''),
                      }}
                      style={{ padding: '1em 0em 1em 0em' }}
                    />               
                    <Link className="button is-small" to={post.slug}>
                      Weiterlesen →
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment SizesImage on File {
    childImageSharp {
      sizes(maxWidth: 300) {
        ...GatsbyImageSharpSizes
      }
    }
  }
  fragment PostListFields on wordpress__POST {
    id
    title
    excerpt
    author {
      name
      slug
      avatar_urls {
        wordpress_48
      }
    }
    date(locale: "de", formatString: "DD MMMM, YYYY")
    slug
    acf {
      feat_img {
        localFile {
          url
          ...SizesImage
        }
      }
    }    
  }
`
